<template>
  <div class="types-bg">
    <TypeTaskSmall :icon="'arrow-short'" label="Задача" />
    <TypeTask v-model="testType" :list="listTypes" :isEdit="true" />
    <TypeTask v-model="testStatus" :list="listStatuses" :isEdit="false" />
  </div>
</template>

<script>
import TypeTaskSmall from "@/modules/UiKit/components/TypeTask/TypeTaskSmall";
import TypeTask from "@/modules/UiKit/components/TypeTask/TypeTask";
export default {
  name: "TypeTaskPage",
  mixins: [],
  props: {},
  components: { TypeTask, TypeTaskSmall },
  data() {
    return {
      testType: { icon: "arrow-short", name: "улучшение" },
      listTypes: [
        { icon: "arrow-short", name: "улучшение" },
        { icon: "arrow-short", name: "задача" },
        { icon: "arrow-short", name: "подзадача" }
      ],
      testStatus: { name: "в работе" },
      listStatuses: [{ name: "создана" }, { name: "готово" }]
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.types-bg {
  display: flex;
  flex-direction: column;

  /deep/ {
    .type-small {
      margin-bottom: 50px;
    }
    .task-type {
      margin-bottom: 100px;
    }
  }
}
</style>
